import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.css']
})
export class NoAccessComponent implements OnInit {
  _lob: string;
  appConfig: any;
  customerSupportUrl: string;
  @Input()
  set lob(lob: string) {
    this._lob = lob;
    console.log(this._lob);
  }
  constructor(private appService: AppService) { }

  ngOnInit() {
    this.appService.getConfigStatus().subscribe((configStatus: boolean) => {
      if (configStatus) {
        this.appConfig = this.appService.getGlobalConfig();
        this.customerSupportUrl = this.appConfig.veriskCustomerSupport;
      }
    });
  }

}
