import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { IUserGuide, DataCubeResponse } from 'src/models';
import { FormControl } from '@angular/forms';
import { Observable, BehaviorSubject, throwError, concat } from 'rxjs';
import { catchError, tap, map, filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { UploadUserGuideService } from './upload-user-guide.service';
import { UserGuideService } from 'src/app/components/user-guide/user-guide.service';
import { IConfig } from '../../../models/index';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { DcubeResponseCacheService } from 'src/app/shared/service/dcube-response-cache.service';
import { element } from 'protractor';
import { FileToUpload } from 'src/models';
import { userguide_resources } from '../../shared/resource/resource';

@Component({
  selector: 'upload-user-guide',
  templateUrl: './upload-user-guide.component.html',
  styleUrls: ['./upload-user-guide.component.css']
}) 
export class UploadUserGuideComponent implements OnInit {
  userGuides: IUserGuide[];
  selectControl = new FormControl();
  errorMessage = '';
  fileToUpload: File = null;
  private appConfig: IConfig;
  isUserAdmin = true;
  isEligible: boolean;
  isTrial = false;
  fileId: any;
  private mdlSampleIsOpen = false;
  uploadMessage = '';
  customerSuportUrl = '';

   ngOnInit(): void {

    this.appService.getConfigStatus().subscribe((configStatus: boolean) => {
      if (configStatus) {
        this.appConfig = this.appService.getGlobalConfig();
        this.customerSuportUrl = this.appConfig.veriskCustomerSupport;
        console.log('UserGuideComponent : getConfigStatus: getUserGuides is being called.');
        this.appService.isUserAdmin(this.cookieService.get('ticket')).then((data) => {
          this.isUserAdmin = data;
        });
        this.getUserGuides();
      }
    });
  }

  FileName = userguide_resources.en.FileName;
  Id = userguide_resources.en.Id;
  Type = userguide_resources.en.Type;
  DataCube = userguide_resources.en.DataCube;

  constructor(private appService: AppService,
    private uploadUserGuideService: UploadUserGuideService,
    private userGuideService: UserGuideService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private cacheService: DcubeResponseCacheService) {

  }

  getUserGuides() {
    this.userGuideService.getAdminUserGuides(this.cookieService.get('ticket')).subscribe((result: DataCubeResponse) => {
      this.userGuides = result && result.userGuides;
    });
  }

  validateFile(fileType: string, fileExtension: string, fileExt: string, fileName: string, fileToUpload: File): string {
    let fileMatch = false;
    let error = '';
    if (fileExtension == 'pdf' && fileType == 'application/pdf') {

      //continue
    } else if (fileExtension == 'xlsx' && fileType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {

      //continue
    } else {
      // error
      return 'Choose a file with extension: ' + fileExt;
    }

    if(fileName.length > 100){
      //error
      return 'File name cannot exceed more than 100 characters.';
    }
    
    const re = /^[a-zA-Z0-9 .,()_-]+$/;
    if(!re.test(fileName)){
      return 'File name has invalid characters. Allowed characters are letters, numbers, spaces and ()-_,.';
    }

    if(fileToUpload.size > 50 * 1024 * 1024){ //50MB
      return 'File size exceeds 50MB.';
    }

    return error; //Empty means is good

  }

  handleFileInput(files: FileList, id: string, fileExt: string) {
    const uGuide = this.userGuides.find(ug => ug.id === id);

    this.fileToUpload = files.item(0);

    const file = new FileToUpload();

    

    // Set File Information
    file.fileId = id;
    file.fileName = this.fileToUpload.name;
    file.fileSize = this.fileToUpload.size;
    file.fileType = this.fileToUpload.type;
    const fileExtension = file.fileName.substring(file.fileName.lastIndexOf(".") + 1);
    const isfileValid = this.validateFile(file.fileType, fileExtension, fileExt, file.fileName, this.fileToUpload);
    if(isfileValid !== ''){
      this.ErrorMessageFileUpload(isfileValid);
      return;
    }



    const reader = new FileReader();

    // Setup onload event for reader
    reader.onload = () => {
      // Store base64 encoded representation of file
      file.fileAsBase64 = reader.result;
      const ticket = this.cookieService.get('ticket');

      this.uploadUserGuideService.getFileLink(uGuide.url, ticket).subscribe(fileUrl => {
        // POST to server
        this.uploadUserGuideService.putFile(<ArrayBuffer>file.fileAsBase64, fileUrl)
          .subscribe(resp => {
            if (resp == null) {
              this.ErrorMessageFileUpload('File Uploaded successfully!');
            } else {
              this.ErrorMessageFileUpload('Error Uploading File!');
            }
          },
          err => {
            this.ErrorMessageFileUpload('Error Uploading File!');
          });
      }, error =>{
        this.ErrorMessageFileUpload('Error Getting Presigned Url! ' + error);
      });

    };
    // Read the file
    reader.readAsArrayBuffer(this.fileToUpload);
  
  }

  ErrorMessageFileUpload(message: string) {
    this.uploadMessage = message;
    this.mdlSampleIsOpen = true;
  }
  private btnOKClick(): void {
    this.mdlSampleIsOpen = false;
  }

}
