import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { AppService } from "src/app/app.service";

@Injectable({
  providedIn: "root"
})
export class ProfileSelectionService {
  userProfileApiUrl: string;
  prdCtx: string;

  constructor(private http: HttpClient, private cookieService: CookieService, private appService: AppService) {
    this.appService.getConfigStatus().subscribe(_configStatus => {
      if (_configStatus) {
        const _appConfig = this.appService.getGlobalConfig();
        this.prdCtx = _appConfig.prdCtx;
        this.userProfileApiUrl = _appConfig.userProfileApiUrl;
      }
    });
  }

  hasCompanyAccess(): Observable<any> {
    if (!localStorage.getItem("userid") || !localStorage.getItem("cstid")) {
      return this.getAllCompanyAccess(this.userProfileApiUrl, this.cookieService.get('ticket'), this.prdCtx)
        .pipe(
          switchMap(allCompanyAccess => {
            if (!allCompanyAccess || allCompanyAccess.length === 0) {
              return of(false); // Return false when user doesn't have any company access
            }
            return of(void 0);
          }),
          catchError(error => {
            return of(void 0);
          })
        );
    } else {
      return of(void 0);
    }
  }

  includeIdealUX(): Observable<void> {
    const queryParams = new URLSearchParams(window.location.search);

    // Check if the "redirectedFromMenu" parameter is set to "true"
    if (queryParams.get('redirectedFromMenu') === 'true' && queryParams.get('switchcompany')) {
      return this.getContexualProfile(this.userProfileApiUrl, this.cookieService.get('ticket'), this.prdCtx)
        .pipe(
          switchMap(contextualProfile => {
            if (contextualProfile.cstid) {
              localStorage.setItem("cstid", (contextualProfile && contextualProfile.cstid) || '');
              localStorage.setItem("userid", (contextualProfile && contextualProfile.userid) || '');
              localStorage.setItem("customername", (contextualProfile && contextualProfile.customerName) || '');
              localStorage.setItem("pcc_highest_parent_cstid", (contextualProfile && contextualProfile.highestParent) || '');
              localStorage.setItem("pcc_ird", (contextualProfile && contextualProfile.ird) || '');
              localStorage.setItem(`profile_selected_${this.cookieService.get('prd_ctx')}`, (contextualProfile && contextualProfile.profileSelected) || '');
            }
            return of(void 0);  // Return an observable that completes
          }),
          catchError(error => {
            return of(void 0);  // Return an observable that completes even on error
          })
        );
    } else {
      return of(void 0);  // Return an observable that completes immediately if conditions are not met
    }
  }

  getContexualProfile(path: string, ticket: string, prdCtx: string): Observable<any> {
    const headers = new HttpHeaders().set("Authorization", `Bearer ${ticket}`);
    const url = `${path}/context/${prdCtx}/v1`;
    return this.http.get(url, { headers: headers }).pipe(tap());
  }

  getAllCompanyAccess(path: string, ticket: string, prdCtx: string): Observable<any> {
    const headers = new HttpHeaders().set("Authorization", `Bearer ${ticket}`);
    const url = `${path}/customer/detail/user/product/${prdCtx}/v1`;
    return this.http.get(url, { headers: headers }).pipe(tap());
  }

  setProductContextCookie() {
    if (!this.cookieService.get("prd_ctx")) {
      this.cookieService.set('prd_ctx', this.prdCtx, null, '/', '', true);
    }
  }
}
