import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class ProfileSelectionInterceptor implements HttpInterceptor {

  constructor(private cookieService: CookieService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.indexOf("api/Dataload/GetDownloadLink?fileName=") >= 0 ||
      request.url.indexOf("api/DataCube/GetListOfEligibleLobs") >= 0 ||
      request.url.indexOf("api/S3/GetS3Content") >= 0 ||
      request.url.indexOf("api/S3") >= 0 ||
      request.url.indexOf("tableau/authenticate") >= 0 ||
      request.url.indexOf("api/Trial/GetTrialLobs") >= 0
    ) {
           
      const nextReq = request.clone({
        headers: request.headers
          .set("customerid", localStorage.getItem("cstid") ? localStorage.getItem("cstid") : "")
          .set("userid", localStorage.getItem("userid") ? localStorage.getItem("userid") : "")
          .set("IsTrial", localStorage.getItem("isTrial") ? localStorage.getItem("isTrial") : "false")
      });
      return next.handle(nextReq).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse) {

            if (error.status === 400 || (error.error && error.error.message === 'InvalidCstId')) {
              localStorage.removeItem("cstid");
              localStorage.removeItem("userid");
              localStorage.removeItem("customername");
              localStorage.removeItem("isTrial");
              localStorage.removeItem(`profile_selected_${this.cookieService.get("prd_ctx")}`);
            }
          }
          return throwError(error);
        }));
    }

    return next.handle(request);
  }
}
