import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { AppComponent } from './app.component';
import { LobListComponent } from './components/lob-list/lob-list.component';
import { VizContainerComponent } from './components/viz-container/viz-container.component';
import { TrialComponent } from './components/trial/trial.component';
import { UploadUserGuideComponent } from './components/upload-user-guide/upload-user-guide.component';
const appRoutes: Route[] = [
  { path: '', component: LobListComponent },
  { path: 'trial', component: TrialComponent },
  { path: 'viz-container', component: VizContainerComponent },
  { path: 'admin', component: UploadUserGuideComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})
export class AppRouterModule { }
