export class S3ContentResponse {
    s3Lobs: S3Lob[];
    isEligible: boolean;
}
export class S3Lob {
    lobCode: string;
    lobDesc: string;
    folders: S3Folder[];
}

export class S3Folder {
    name: string;
    //folders: S3Folder[];
    files: S3File[];
}

export class S3File {
    name: string;
    size: string;
    key: string;
    lastModified: string;
}

export class UrlFile{
    url: string;
}