import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse} from '@angular/common/http';
import { AppService } from 'src/app/app.service';
import { catchError, tap, map, filter } from 'rxjs/operators';
import { IConfig } from '../../../models/index';
import { IUserGuide, FileToUpload, DataCubeResponse } from 'src/models';

@Injectable({
  providedIn: 'root'
})
export class UploadUserGuideService {

  userGuide: IUserGuide;
  private appConfig: IConfig;
  constructor(private appService: AppService, private http: HttpClient) {
    this.appService.getConfigStatus().subscribe((configStatus: boolean) => {
      if (configStatus) {
        this.appConfig = this.appService.getGlobalConfig();
      }
    });
   
   
  }

  postUserGuideFile(data: FormData): Observable<boolean>{
    if (this.appConfig && this.appConfig.ticket) {
      data.set("ticket", this.appConfig.ticket);
    }
    return this.http.post<boolean>(`${this.appConfig.datacubeApiUrl}/api/Dataload/UploadFiles`, data).pipe(
      map(data =>{
        return data;
      }),
      catchError(this.handleError)
    );
  }

  putFile(fileToUpload: ArrayBuffer, Url: string): Observable<boolean> {
    this.appConfig = this.appService.getGlobalConfig();   
    return this.http.put<boolean>(`${Url}`, fileToUpload,{headers:{'content-type':'application/octet-stream'}}).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)

    );

  }

  getFileLink(url: string, ticket: string): Observable<string>{
    if (this.appConfig && this.appConfig.ticket) {
      ticket = this.appConfig.ticket;
    }
    return this.http.get(`${this.appConfig.datacubeApiUrl}/api/Dataload/GetUploadLink?url=${url}&ticket=${ticket}`,{responseType:'text'})
    .pipe(map((res:any) => {
      return res;
    }),
    catchError(this.handleError));
  }
 
  getUserGuides(ticket: string): Observable<DataCubeResponse> {
    if (this.appConfig) {
      return this.http.get<DataCubeResponse>(`${this.appConfig.datacubeApiUrl}/api/Dataload/GetListOfUserGuides/${ticket}`).pipe(
        map(data => {          
          console.log('GetListOfuserguides DataCubeResponse: ' + JSON.stringify(data));
          return data;
        }),
        catchError(this.handleError)
      );
    } 
  }
 
  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    //console.log(err);
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error is: ${err.error}`;
    }
    //console.error(errorMessage);
    return throwError(errorMessage);
  }

}
