import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataCubeResponse, ILob, IUserGuide } from '../../../models';
import { shareReplay, map, tap } from 'rxjs/operators';
import { AppService } from '../../../app/app.service';
const CACHE_SIZE = 1;
@Injectable({
  providedIn: 'root'
})
export class DcubeResponseCacheService {
  private cache$: Observable<DataCubeResponse>;
  private trialCache$: Observable<DataCubeResponse>;
  constructor(private appService: AppService) { }

  getDcubeResponse(ticket: string) {
    if (!this.cache$) {
      this.cache$ = this.requestData(ticket).pipe(
        shareReplay(CACHE_SIZE)
      );
    }
    return this.cache$;
  }

  private requestData(ticket: string) {
    return this.appService.getDcubeResponse(ticket).pipe(
      tap(response => response)
    );
  }

  getTrialDcubeResponse(ticket: string) {
    if (!this.trialCache$) {
      this.trialCache$ = this.requestTrialData(ticket).pipe(
        shareReplay(CACHE_SIZE)
      );
    }
    return this.trialCache$;
  }

  private requestTrialData(ticket: string) {
    return this.appService.getTrialDcubeResponse(ticket).pipe(
      tap(response => response)
    );
  }

  getAdminUserGuides(ticket: string) {
    if (!this.cache$) {
      this.cache$ = this.AdminUserguides(ticket).pipe(
        shareReplay(CACHE_SIZE)
      );
    }
    return this.cache$;
  }

  private AdminUserguides(ticket: string) {
    return this.appService.getAdminUserguides(ticket).pipe(
      tap(response => response)
    );
  }
}
