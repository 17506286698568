
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRouterModule } from './app-router.module';
import { CommonModule } from '@angular/common';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { UserGuideComponent } from './components/user-guide/user-guide.component';
import { UploadUserGuideComponent } from './components/upload-user-guide/upload-user-guide.component';
//import { UploadContainer } from './components/Upload-container/Upload-container.component';
import { UserProfileFilterPipe } from './shared/pipes/userprofilefilter.pipe';
import { FooterLinksSortPipe } from './shared/pipes/footerlinks.pipe';
import { CookieService } from 'ngx-cookie-service';
import { AppService} from '../app/app.service';
import { HeaderService } from './components/header/header.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { UserGuideService } from './components/user-guide/user-guide.service';
import { UploadUserGuideService } from './components/upload-user-guide/upload-user-guide.service'

import { LobListComponent } from './components/lob-list/lob-list.component';
import { LobListService } from './components/lob-list/lob-list.service';
import { VizContainerComponent } from './components/viz-container/viz-container.component';
import { RouterModule } from '@angular/router';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { TrialComponent } from './components/trial/trial.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { DownloadFilesComponent } from './download-files/download-files.component';
import { DownloadFilesService } from './download-files/download-files.service';




@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    OAuthModule.forRoot()
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    UserGuideComponent,
    UploadUserGuideComponent,
    DownloadFilesComponent,
    LobListComponent,
    VizContainerComponent,
    UnauthorizedComponent,
    TrialComponent,
    NoAccessComponent,
    UserProfileFilterPipe,
    FooterLinksSortPipe
  ],
  providers: [
    HeaderService,
    CookieService,
    AppService,
    UserGuideService,
    UploadUserGuideService,
    DownloadFilesService,
    LobListService
  ],
  bootstrap: [ AppComponent ],
  exports: [RouterModule]
})
export class AppModule { }
