import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, tap, map, filter, retry } from 'rxjs/operators';
import { IUseCase, IProduct, IConfig, IDcLob, IUserGuide, DataCubeResponse, ILob, S3ContentResponse } from '../models/index';
@Injectable()
export class AppService {

    private useCaseModel = '../assets/jsons/usecases.json';
    private productModel = '../assets/jsons/products.json';
    private globalConfig = '../assets/jsons/config.json';
    private dcLobConfig = '../assets/jsons/dclobs.json';
    private userGuidesConfig = '../assets/jsons/userguides.json';

    private appConfig: IConfig;
    private appConfigLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private userGuidesLoaded: BehaviorSubject<IUserGuide[]> = new BehaviorSubject([]);
    private userGuidesLoadedAll: BehaviorSubject<IUserGuide[]> = new BehaviorSubject([]);
    private lobsLoaded: BehaviorSubject<ILob[]> = new BehaviorSubject([]);
    private isTrial: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public IsEligible: BehaviorSubject<boolean>=new BehaviorSubject(false);

    constructor(private http: HttpClient) {
        this.loadConfig();
    }
    loadConfig() {
        this.http.get<IConfig>(this.globalConfig).subscribe(
            _config => {
                this.appConfig = _config;
                this.appConfigLoaded.next(true);
            }
        );
    }

    getUseCases(): Observable<IUseCase[]> {
        return this.http.get<IUseCase[]>(this.useCaseModel).pipe(
            tap(data => console.log('JSON Data: ' + JSON.stringify(data))),
            catchError(this.handleError)
        );
    }

    getUserGuides(): Observable<IUserGuide[]> {
        return this.http.get<IUserGuide[]>(this.userGuidesConfig).pipe(
            catchError(this.handleError)
        );
    }

    getDcubeResponse(ticket: string): Observable<DataCubeResponse> {
        if (this.appConfig) {
            if (this.appConfig.ticket) {
                ticket = this.appConfig.ticket;
            }
            return this.http.get<DataCubeResponse>(`${this.appConfig.datacubeApiUrl}/api/DataCube/GetListOfEligibleLobs/${ticket}`).pipe(
                map(data => {
                    this.userGuidesLoaded.next(data.userGuides);
                    this.lobsLoaded.next(data.lobs);
                    console.log('GetListOfEligibleLobs DataCubeResponse: ' + JSON.stringify(data));
                    return data;
                }),
                catchError(this.handleError)
            );
        } else {
            this.loadConfig();
        }
    }
    getTrialDcubeResponse(ticket: string): Observable<DataCubeResponse> {
        console.log('In Trial');
        if (this.appConfig) {
            if (this.appConfig.ticket) {
                ticket = this.appConfig.ticket;
            }
            return this.http.get<DataCubeResponse>(`${this.appConfig.datacubeApiUrl}/api/Trial/GetTrialLobs/${ticket}`).pipe(
                map(data => {
                    this.userGuidesLoaded.next(data.userGuides);
                    this.lobsLoaded.next(data.lobs);
                    console.log('GetTrialLobs DataCubeResponse: ' + JSON.stringify(data));
                    return data;
                }),
                catchError(this.handleError)
            );
        } else {
            this.loadConfig();
        }
    }

    getAdminUserguides(ticket: string): Observable<DataCubeResponse> {
        console.log('In Admin');
        if (this.appConfig) {
            if (this.appConfig.ticket) {
                ticket = this.appConfig.ticket;
            }
            return this.http.get<DataCubeResponse>(`${this.appConfig.datacubeApiUrl}/api/Dataload/GetListOfUserGuides/${ticket}`).pipe(
                map(data => {
                    this.userGuidesLoadedAll.next(data.userGuides);
                    console.log('GetList of Userguides: ' + JSON.stringify(data));
                    return data;
                }),
                catchError(this.handleError)
            );
        } else {
            this.loadConfig();
        }
    }
    getDcLobs(): Observable<IDcLob[]> {
        return this.http.get<IDcLob[]>(this.dcLobConfig).pipe(
            tap(data => console.log('Lob Data: ' + JSON.stringify(data))),
            catchError(this.handleError)
        );
    }
    getS3Objects(ticket: string): Observable<S3ContentResponse> {
        if (this.appConfig.ticket) {
            ticket = this.appConfig.ticket;
        }
        return this.http.get(`${this.appConfig.datacubeApiUrl}/api/S3/GetS3Content/${ticket}?timestamp=${Date.now()}`,
            { responseType: 'json' }).pipe(map((res: any) => res));
    }

    getUseCase(slug: string): Observable<IUseCase> {
        return this.http.get<IUseCase>(this.useCaseModel)
            .pipe(
                filter(response => response.slug === slug)
            );
    }

    getProducts(): Observable<IProduct[]> {
        console.log('getProducts Called');
        return this.http.get<IProduct[]>(this.productModel).pipe(
            tap(data => console.log('JSON Data: getProducts::' + data.length)),
            catchError(this.handleError)
        );
    }

    getConfigStatus(): Observable<boolean> {
        return this.appConfigLoaded.asObservable();
    }

    getUserGuideStatus(): Observable<IUserGuide[]> {
        return this.userGuidesLoaded.asObservable();
    }
    getLobStatus(): Observable<ILob[]> {
        return this.lobsLoaded.asObservable();
    }

    getGlobalConfig(): IConfig {
        return this.appConfig;
    }


    async isUserAdmin(ticket: string) {
        if (this.appConfig.ticket) {
            ticket = this.appConfig.ticket;
        }
        const customer = await this.http.get<IConfig>(this.globalConfig).toPromise();

        const contract = await this.http.get<boolean>(`${customer.datacubeApiUrl}/api/Dataload/IsUserAdmin/${ticket}`).toPromise();

        return contract;
    }

    setTrialStatus() {
        this.isTrial.next(true);
    }
    getTrialStatus() {
        return this.isTrial.asObservable();
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}
