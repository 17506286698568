import { Component, OnInit } from '@angular/core';
import { AppService } from './app.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthConfig, OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { EntitlementService } from './shared/service/entitlement.service';
import { PRIMARY_HOST_DOMAIN } from './shared/constants/app.constants';
import { LobListService } from './components/lob-list/lob-list.service';
import { DataCubeResponse } from 'src/models';


@Component({
  selector: 'app-datacube',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'DataCube';
  authConfig: AuthConfig = {};
  errorMessage = '';
  isAuthenticated = false;
  isEligible = true;
  viz: any;
  customerSuportUrl: string;
  isTrial = false;
  isUserEligible = false; 
  lobsLoaded = false;
  constructor(
    private appService: AppService,
    private oauthService: OAuthService,
    private router: Router,
    private cookieService: CookieService,
    private entitlementService: EntitlementService,
    private lobListService: LobListService
  ) {
    this.router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });
    this.appService.getConfigStatus().subscribe(
      _configStatus => {
        if (_configStatus) {
          const _appConfig = this.appService.getGlobalConfig();
        this.lobListService.getLobList(this.cookieService.get('ticket'), this.isTrial).subscribe((result: DataCubeResponse) => {
          this.isUserEligible = result && result.isEligible;
          this.lobsLoaded = true;
          console.log("isUserEligible"+ result.isEligible);
        });
          
          this.authConfig.issuer = _appConfig.identityUrl;
          this.authConfig.redirectUri = _appConfig.redirectUrl;
          this.authConfig.scope = 'openid';
          this.authConfig.logoutUrl = _appConfig.identityUrl + '/connect/endsession';
          this.authConfig.clientId = _appConfig.clientId;
          this.authConfig.redirectUri = _appConfig.redirectUrl;
          if (this.isTrial) {
            this.authConfig.clientId = _appConfig.trialClientId;
            this.authConfig.redirectUri = _appConfig.trialRedirectUrl;
          }
           this.configureWithNewConfigApi();
           this.setAuthenticationStatus();
        }
      },
      error => this.errorMessage = <any>error
    );
  }

  private configureWithNewConfigApi() {
    this.oauthService.configure(this.authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }
  setAuthenticationStatus() {
    this.entitlementService.getLoginStatus().subscribe(
      status => {
        this.isAuthenticated = status;
      }
    );
  }

  ngOnInit(): void {
  }

  checkRouterEvent(routerEvent: Event): void {
   // debugger;
    if (routerEvent instanceof NavigationStart) {
      const location = routerEvent.url.toLowerCase();
      const hasTrial = location.indexOf('/trial') !== -1;
      const hasTrialQuery = location.indexOf('&trial=trial') !== -1;
      const hasTrialSub = location.indexOf('generalliability-sizeofrisk-trial') !== -1;

      if (hasTrial || hasTrialQuery || hasTrialSub)  {
        this.isTrial = true;
        this.appService.setTrialStatus();
      }
    }

    if (routerEvent instanceof NavigationEnd) {
      if (this.cookieService.get('ticket')) {
        if (this.oauthService.getIdToken()) {
          this.entitlementService.setLoginStatus(true);
        } else if (!new RegExp('#id_token=.+').test(location.hash)) {
          this.entitlementService.setLoginStatus(false);
          this.oauthService.initImplicitFlow();
        }
      } else {
        if (this.oauthService.getIdToken()) {
          this.cookieService.deleteAll('/', PRIMARY_HOST_DOMAIN);
          this.entitlementService.setLoginStatus(false);
          this.oauthService.logoutUrl = JSON.parse(sessionStorage.getItem('id_token_claims_obj')).iss + '/connect/endsession';
          // 'https://idt.iso.com/identity/connect/endsession'
          this.oauthService.logOut();
        } else {
          this.oauthService.initImplicitFlow();
        }
      }
    }

    if (routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
    }
  }
}
