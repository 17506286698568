import { Injectable } from '@angular/core';
import { IConfig, S3ContentResponse, UrlFile } from 'src/models';
import { AppService } from '../app.service';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders } from '@angular/common/http';
import { DcubeResponseCacheService } from '../shared/service/dcube-response-cache.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
  export class DownloadFilesService {

    private appConfig: IConfig;
    constructor(private appService: AppService, private http: HttpClient) {
      this.appService.getConfigStatus().subscribe((configStatus: boolean) => {
        if (configStatus) {
          this.appConfig = this.appService.getGlobalConfig();
        }
      });
    }
    getS3Objects(ticket: string): Observable<S3ContentResponse> {
        return this.appService.getS3Objects(ticket).pipe(map((res: any) => res));
      }
    
    getFlatFileLink(key: string): Observable<UrlFile>{
      return this.http.post<UrlFile>(`${this.appConfig.datacubeApiUrl}/api/S3`, {key});
    }

    downloadFile(url: string): Observable<HttpEvent<any>>{
      return this.http.get(url ,  {
        responseType: "blob", reportProgress: true, observe: "events", headers: { accept: 'application/octet-stream' }
      });
    }
}
