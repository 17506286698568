import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from 'events';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-trial',
  templateUrl: './trial.component.html',
  styleUrls: ['./trial.component.css']
})
export class TrialComponent implements OnInit {

  constructor(private appService: AppService) {
    // console.log('TrialComponent : constructor : status is set to trial');
    // this.appService.setTrialStatus();
  }

  ngOnInit() {
  }

}
