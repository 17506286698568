import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IProfileLinks } from '../../../models/profilelink.model';
import { IProduct } from './header.model';

@Injectable()
export class HeaderService {

    constructor(private http: HttpClient) {
    }

    getAllUserProfileLinks(datacubeApiUrl: string): Observable<IProfileLinks[]> {
        return this.http.get<IProfileLinks[]>(`${datacubeApiUrl}/api/Dataload/getAllUserProfileLinks`).pipe(
          catchError(this.handleError)
        );
    }

    getEntitledUserProfileLinks(userId: string, token: string, datacubeApiUrl: string): Observable<IProfileLinks[]> {
        return this.http.get<IProfileLinks[]>(`${datacubeApiUrl}/API/Dataload/getEntitledUserProfileLinks?token=${token}&userId=${userId}`).pipe(
          catchError(this.handleError)
        );
    }

    getUserProducts(userId: string, token: string, datacubeApiUrl: string, appId: string): Observable<IProduct[]> {
        return this.http.get<IProduct[]>(`${datacubeApiUrl}/api/Dataload/getUserProducts?userId=${userId}&token=${token}&appId=${appId}`).pipe(
          catchError(this.handleError)
        );
    }

    getAllProducts(datacubeApiUrl: string) {
        return this.http.get(`${datacubeApiUrl}/api/Dataload/getAllProducts`).pipe(
          catchError(this.handleError)
        );
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}
