import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { HeaderService } from '../header/header.service';
import { IProfileLinks } from '../../../models/profilelink.model';
import { ISO_FOOTER_FILTER_ARGS } from '../../shared/constants/app.constants';
import { EntitlementService } from '../../shared/service/entitlement.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {

  footerLinks = { corporateHome: '', termsAndConditions: '', privacyAndPolicy: '', contactUs: '' };
  errorMessage = '';
  lastHeight = 0;
  footerFilterArgs = ISO_FOOTER_FILTER_ARGS;
  userProfileLinks: IProfileLinks[];
  currentYear = new Date().getFullYear();
  isAuthenticated = false;

  constructor(
    private headerService: HeaderService,
    private appService: AppService,
    private entitlementService: EntitlementService) {
      this.appService.getConfigStatus().subscribe(
        _configStatus => {
          if (_configStatus) {
            this.headerService.getAllUserProfileLinks(this.appService.getGlobalConfig().datacubeApiUrl).subscribe(
              _userProfileLinks => {
                this.userProfileLinks = _userProfileLinks;

                this.setUserProfile(_userProfileLinks)
              },
              error => this.errorMessage = <any>error
            );
          }
        }
      );
  }

  ngOnInit(): void {
    // setInterval(() => {
    //   let newHeight = document.getElementById('main').scrollHeight
    //   if(this.lastHeight !== newHeight){
    //     this.lastHeight = newHeight
    //     document.getElementById('footer').style.top = (newHeight + 94) + "px"
    //   }
    // }, 500)

    this.entitlementService.getLoginStatus().subscribe(
      status => {
        this.isAuthenticated = status;
      }
    );
  }
  
  setUserProfile(_userProfileLinks) {
    _userProfileLinks.forEach(item => {
      if (item.code === 'ISO_HOME') {
        this.footerLinks.corporateHome = item.url;
      }
      if (item.code === 'TERMS') {
        this.footerLinks.termsAndConditions = item.url;
      }
      if (item.code === 'POLICY') {
        this.footerLinks.privacyAndPolicy = item.url;
      }
      if (item.code === 'CONTACT') {
        this.footerLinks.contactUs = item.url;
      }
    });
  }
}
