import { Component, Inject, OnInit } from '@angular/core';
import { IConfig, S3ContentResponse, S3Lob, S3Folder, UrlFile } from 'src/models';
import { DownloadFilesService } from './download-files.service';
import { AppService } from '../app.service';
import { CookieService } from 'ngx-cookie-service';
import { TrialClients } from '../../models';
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { downloadfiles_resources } from '../shared/resource/resource';


@Component({
  selector: 'app-download-files',
  templateUrl: './download-files.component.html',
  styleUrls: ['./download-files.component.css']
})
export class DownloadFilesComponent implements OnInit {
  appConfig: IConfig;
  s3Lobs: S3Lob[];
  isEligible: boolean;
  isTrial: boolean;
  fiscalTrialClients: TrialClients;
  showSpinner: boolean = false;
  progress: number;

// resources
 matFoldIcon = downloadfiles_resources.en.folder;

  constructor(private appService: AppService,private downloadService: DownloadFilesService, private cookieService: CookieService) { }

  ngOnInit() {
    this.appService.getConfigStatus().subscribe((configStatus: boolean) => {
      if (configStatus) {
        this.fiscalTrialClients = this.appService.getGlobalConfig().fiscalTrialClients;
        this.appService.getTrialStatus().subscribe((trialStatus: boolean) => {
          this.isTrial = trialStatus;
          console.log(`DownloadFilesComponent: isTrial: ${this.isTrial}`);
          if (!this.isTrial) {
            this.getS3Content();
          }
        });
      }
    });
  }

  getS3Content() {
    this.downloadService.getS3Objects(this.cookieService.get('ticket')).subscribe((res: S3ContentResponse) => {
      if (res) {
        this.s3Lobs = this.gets3LobListBasedOnTrialClients(res.s3Lobs);
        this.isEligible = res.isEligible;
        this.appService.IsEligible.next(res.isEligible);
      }
    });
  }

  getDataTarget(lob: S3Lob, folder: S3Folder) {
    return '#' + lob.lobCode + folder.name;
  }

  getId(lob: S3Lob, folder: S3Folder) {
    return lob.lobCode + folder.name;
  }

  gets3LobListBasedOnTrialClients(list: S3Lob[]): S3Lob[]{
    const custid = localStorage.getItem('cstid');
    //is HO-Fiscal client
    const isHoFiscal = this.fiscalTrialClients.HO.indexOf(+custid);
    if(isHoFiscal === -1){
      const index = list.findIndex(x => x.lobCode === "HO-Fiscal");
      if(index !== -1)
        list.splice(index, 1);
    }
    const isPAFiscal = this.fiscalTrialClients.PA.indexOf(+custid);
    if(isPAFiscal === -1){
      const index = list.findIndex(x => x.lobCode === "PA-Fiscal");
      if(index !== -1)
       list.splice(index, 1);
    }
    const isBPFiscal = this.fiscalTrialClients.BO.indexOf(+custid);
    if(isBPFiscal === -1){
      const index = list.findIndex(x => x.lobCode === "BP-Fiscal");
      if(index !== -1)
        list.splice(index, 1);
    }
    const isCAFiscal = this.fiscalTrialClients.CA.indexOf(+custid);
    if(isCAFiscal === -1){
      const index = list.findIndex(x => x.lobCode === "CA-Fiscal");
      if(index !== -1)
        list.splice(index, 1);
    }
    const isCFFiscal = this.fiscalTrialClients.CF.indexOf(+custid);
    if(isCFFiscal === -1){
      const index = list.findIndex(x => x.lobCode === "CF-Fiscal");
      if(index !== -1)
        list.splice(index, 1);
    }
    const isGLFiscal = this.fiscalTrialClients.GL.indexOf(+custid);
    if(isGLFiscal === -1){
      const index = list.findIndex(x => x.lobCode === "GL-Fiscal");
      if(index !== -1)
        list.splice(index, 1);
    }
    return list;
  }

  downloadFile(key: string){
    this.showSpinner = true;
    const fileName = key.split('/')[2];
    this.downloadService.getFlatFileLink(key).subscribe((fileUrl: UrlFile) =>{
      if(fileUrl.url){
        console.log(fileUrl);
      this.downloadService.downloadFile(fileUrl.url).subscribe(event => {
        if (event.type === HttpEventType.DownloadProgress) {
          // This is an Download progress event. Compute and show the % done:
          const percentDone = Math.round(100 * event.loaded / event.total);
          this.progress = percentDone;
        } else if (event instanceof HttpResponse) {
          console.log('File is completely Downloaded!');
          if (window.navigator.msSaveOrOpenBlob) { // for IE and Edge
            window.navigator.msSaveBlob(event['body'], fileName);
          } else {
            const url = window.URL.createObjectURL(event['body']);
            const a = document.createElement('a');
            a.setAttribute('type', 'hidden');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
            this.showSpinner = false;
            this.progress=0;
          }
        }
         
        }, err =>{
          this.showSpinner = false;
          if(err.status == 403){
            alert("This file is not available for download.")
          }
        })
      } else{
        this.showSpinner = false;
      }
    }, err =>{
      this.showSpinner = false;
    });

    return false;
  }
}
