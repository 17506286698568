import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {
  appConfig: any;
  customerSupportUrl: string;
  productName = 'DataCube';
  constructor(private appService: AppService) {
    this.appService.getTrialStatus().subscribe(isTrial => {
      if (isTrial) {
        this.productName = 'DataCube Trial';
      }

    });
  }

  ngOnInit() {
    this.appService.getConfigStatus().subscribe((configStatus: boolean) => {
      if (configStatus) {
        this.appConfig = this.appService.getGlobalConfig();
        this.customerSupportUrl = this.appConfig.veriskCustomerSupport;
      }
    });
  }

}
