import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras, Params } from '@angular/router';
import { EntitlementService } from '../../shared/service/entitlement.service';
import { AppService } from '../../app.service';
import { CookieService } from 'ngx-cookie-service';
import { ILob, DataCubeResponse, TrialClients } from '../../../models';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { LobListService } from '../lob-list/lob-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as Tableau from 'tableau-api-js';
import { vizcontainer_resources } from '../../shared/resource/resource';
declare var tableau: any;

@Component({
  selector: 'app-viz-container',
  templateUrl: './viz-container.component.html',
  styleUrls: ['./viz-container.component.css']
})
export class VizContainerComponent implements OnInit {
  isTrial = false;
  dashboardUrl: string;
  webEditUrl: string;
  viz1: any;
  viz2: any;
  appConfig: any;
  isAuthenticated = false;
  selectedLob: string;
  lobs: ILob[];
  isEligible: boolean;
  lobsLoaded: boolean;
  isSelectedLobEligible = false;
  showFiscalWBLink = false;
  fiscalWBCALink :string;
  fiscalWBLink: string;
  fiscalWBPALink: string;
  fiscalWBBOLink : string;
  fiscalWBGLLink : string;
  fiscalWBCFLink : string;
  fiscalTrialClients: TrialClients;
  isFiscalSelected = false;
  states: any[];
  HO_PA_Selected: string;
  marketlandscapeUrl: string = '';
  @ViewChild('lobButton') lobButtom : ElementRef;
  @ViewChild('menulob') menulob : ElementRef;
  @ViewChild('subButton') subButton : ElementRef;
  isLobOpen= false;
  isOpenNewTab:boolean = false;
  newTabLobName:string = ''

  // resources
  Annual = vizcontainer_resources.en.Annual;
  Fiscal = vizcontainer_resources.en.Fiscal;
  MarketLandscape = vizcontainer_resources.en.MarketLandscape;
  


  public constructor(private route: ActivatedRoute, private router: Router,
    private entitlementService: EntitlementService, private appService: AppService, private cookieService: CookieService,
    private lobListService: LobListService, private spinner: NgxSpinnerService, private renderer:Renderer2) {
    this.appService.getTrialStatus().subscribe(isTrial => {
      console.log(`getTrialStatus.subscribe : isTrial : ${this.isTrial}`);
      this.isTrial = isTrial;
    });

    this.renderer.listen('window', 'click', (e:Event)=>{
      if(e.target !== this.lobButtom.nativeElement && e.target !== this.menulob.nativeElement){
        this.isLobOpen = false
      }
    })
  }
  getLobListForDropDown(dUrl: string, wUrl: string) {
    console.log(`getLobListForDropDown : isTrial : ${this.isTrial}`);
    this.lobListService.getLobList(this.cookieService.get('ticket'), this.isTrial).subscribe((result: DataCubeResponse) => {
      this.lobs = result && result.lobs;
      this.isEligible = result && result.isEligible;
      console.log(`getLobListForDropDown : isEligible: ${this.isEligible}`);
      this.lobsLoaded = true;
      console.log(`Selected Lob: ${this.selectedLob }`)
      console.log(`Selected Lob: ${this.isOpenNewTab }`)
     const lobInContext = this.lobs && this.lobs.find(lob => lob.lobDescription === this.selectedLob && (!this.isOpenNewTab || lob.lobName === this.newTabLobName));
      console.log(`getLobListForDropDown : Lob in Context: ${lobInContext && lobInContext.lobName}`);
      this.isSelectedLobEligible = lobInContext && lobInContext.isAuthorized;
      if (this.isSelectedLobEligible) {
        if(this.selectedLob == 'Personal Auto'){
          this.HO_PA_Selected = "PA";
        } 
        if(this.selectedLob == "Homeowners"){
          this.HO_PA_Selected = "HO";
        }
        if(this.selectedLob == "Businessowners"){
          this.HO_PA_Selected = "BO";
        }
        if(this.selectedLob == "Commercial Auto"){
          this.HO_PA_Selected = "CA";
        }
        if(this.selectedLob == "General Liability"){
          this.HO_PA_Selected = "GL";
        }
        if(this.selectedLob == "Commercial Property"){
          this.HO_PA_Selected = "CF";
        }
        //const custid ='167052';
        const custid = this.cookieService.get('cstid');
        this.showFiscalWBLink = ((this.selectedLob == 'Homeowners'   && this.fiscalTrialClients.HO.indexOf(+custid) >= 0) || 
        (this.selectedLob == 'Personal Auto' && this.fiscalTrialClients.PA.indexOf(+custid) >= 0) ||
        (this.selectedLob == 'Businessowners' && this.fiscalTrialClients.BO.indexOf(+custid) >= 0) ||
        (this.selectedLob == 'Commercial Auto' && this.fiscalTrialClients.CA.indexOf(+custid) >= 0) ||
        (this.selectedLob == 'General Liability' && this.fiscalTrialClients.GL.indexOf(+custid) >= 0) ||
        (this.selectedLob == 'Commercial Property' && this.fiscalTrialClients.CF.indexOf(+custid) >= 0)) 
        && !this.isTrial;
        
        if(this.showFiscalWBLink){
          // we are showing the feature to toggle between fiscal and annual
          // so we neet to store states to toggle between
          this.storeState();
          this.isFiscalSelected = this.webEditUrl == this.fiscalWBLink || this.webEditUrl == this.fiscalWBPALink || this.webEditUrl == this.fiscalWBBOLink ||this.webEditUrl == this.fiscalWBCALink ||this.webEditUrl == this.fiscalWBGLLink ||this.webEditUrl == this.fiscalWBCFLink;
        }
        this.getTableauToken(dUrl, wUrl);
      } else {
        this.spinner.hide();
      }
    });
  }
  ngOnInit() {
    this.appService.getConfigStatus().subscribe((configStatus: boolean) => {
      if (configStatus) {
        console.log(`Configuration Status: ${configStatus}`);
        this.appConfig = this.appService.getGlobalConfig();
        this.fiscalTrialClients = this.appConfig.fiscalTrialClients;
        this.fiscalWBLink = this.appConfig.fiscalWBLink;
        this.fiscalWBPALink = this.appConfig.fiscalWBPALink;
        this.fiscalWBBOLink= this.appConfig.fiscalWBBOLink;
        this.fiscalWBCALink = this.appConfig.fiscalWBCALink;
        this.fiscalWBGLLink= this.appConfig.fiscalWBGLLink;
        this.fiscalWBCFLink= this.appConfig.fiscalWBCFLink;
        const querystring = window.location.search;
        if(querystring.toLowerCase().indexOf('trial=trial') >= 0){
          this.marketlandscapeUrl = this.appConfig.marketlandscapeUrl + '/trial';
        } else{
          this.marketlandscapeUrl = this.appConfig.marketlandscapeUrl;
        }
        this.route.queryParams.subscribe(params => {
          console.log(`Route changed: lob ${params['lob']}`);
          this.spinner.show();
          this.selectedLob = params['lob'];
          this.isOpenNewTab =  params['class'] && params['class'] === 'sub' ? true : false;
          if(params['class'] && params['class'] === 'sub') {
              this.newTabLobName =  params['lobName'] ;
          }
          this.dashboardUrl = params['dashboardUrl'];
          this.webEditUrl = params['webEditUrl'];
          this.getLobListForDropDown(params['dashboardUrl'], params['webEditUrl']);
        });
      }
    });
  }

  toggleLob(){
    this.isLobOpen = !this.isLobOpen
  }

  disposeViz() {
    const vizs = tableau.VizManager.getVizs();
    if (vizs && vizs.length > 0) {
      vizs.forEach(viz => {
        viz.dispose();
      });
      console.log(`Viz disposed.`);
    }
  }

  openTableauDashboard(dashboardUrl: string, webEditUrl: string): void {
    this.disposeViz();
    const _this = this;
    console.log(dashboardUrl);
    console.log(webEditUrl);
    const placeholderDiv1 = document.getElementById('vizContainer1');
    const options = {
      hideTabs: true,
      width: '0%',
      height: '0px',
      onFirstInteractive: function () {
        console.log('openTableauDashboard : Tableau Dashboard Viz finished loading');
        _this.openWebEdit(webEditUrl);
      }
    };
    try {
      this.viz1 = new Tableau.Viz(placeholderDiv1, dashboardUrl, options);
    } catch (e) {
      console.log('error : ', e);
    }
  }
  openWebEdit(webEditUrl: string) {
    this.disposeViz();
    const placeholderDiv2 = document.getElementById('vizContainer2');
    const options = {
      hideTabs: true,
      width: '100%',
      height: '745px',
      onFirstInteractive: function () {
        console.log('openWebEdit : Tableau Web Edit Viz finished loading');
      }
    };
    this.spinner.hide();
    try {
      this.viz2 = new Tableau.Viz(placeholderDiv2, webEditUrl, options);
    } catch (e) {
      console.log('error : ', e);
    }
  }

  getTableauToken(dUrl: string, wUrl: string): void {
    let ticket = this.cookieService.get('ticket');
    if (this.appConfig && this.appConfig.ticket) {
      ticket = this.appConfig.ticket;
    }
    this.entitlementService.getTableauToken(this.appConfig.datacubeApiUrl, ticket,this.isTrial).subscribe(
      tableauToken => {
        if (tableauToken === '-1') {
          console.log('getTableauToken: Error generating Tableau token: ' + tableauToken);
          this.spinner.hide();
        } else {
          console.log('getTableauToken : Tableau token generated successsfully... ' + tableauToken);
          const dashBoardUrl =
            `${this.appConfig.tableauHostUrl}/trusted/${tableauToken}/t/${this.appConfig.tableauSite}${dUrl}`;
          const wedEditUrl = `${this.appConfig.tableauHostUrl}/t/${this.appConfig.tableauSite}${wUrl}`;
          console.log('getTableauToken : Tableau Dashboard Url is generated successsfully... ' + dashBoardUrl);
          console.log('getTableauToken : Tableau Web Edit Url is generated successsfully... ' + wedEditUrl);
          if(this.webEditUrl!="" ) 
          {
            this.openTableauDashboard(dashBoardUrl, wedEditUrl);
          }
          else
          {
            this.openWebEdit(dashBoardUrl);
          }
        }
      }
    );
  }

  loadTableauFromDropDown(lob: ILob) {
    try {
      this.isFiscalSelected = false;
    if(lob.class==="sub")
    {
      if( ((this.selectedLob === lob.lobDescription) &&(this.newTabLobName===lob.lobName)) || (!lob.isAuthorized)) { 
        this.isLobOpen = false;
        this.newTabLobName=lob.lobName;
        return; }
    }
     else if( this.selectedLob === lob.lobDescription || !lob.isAuthorized) { 
        return;
       }
      this.spinner.show();
      console.log(`loadTableauFromDropDown : Selected Lob: ${lob}`);
      this.changeRoute(lob);
      if(lob.class == 'sub'){       
        this.newTabLobName=lob.lobName;     
        return;
      }
      else{
        this.isOpenNewTab=false;
      }
      this.selectedLob = lob.lobDescription;
    } catch (e) {
      this.spinner.hide();
    }
  }

  public changeRoute(selectedLob: ILob) {
    const queryParams: Params = {
      lob: selectedLob.lobDescription,
      dashboardUrl: selectedLob.tableauUrl,
      webEditUrl: selectedLob.webEditUrl,
      class:selectedLob.class,
      lobName:selectedLob.lobName
    };
    if(selectedLob.class === 'sub'){
      this.newTab(queryParams)
      this.spinner.hide();

    } else {
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: queryParams,
          queryParamsHandling: 'merge',
        
        } );
    }  
  }

  newTab(queryData) {
     let link = this.router.serializeUrl(this.router.createUrlTree(['viz-container'], { queryParams: queryData }));
     window.open(`${window.location.protocol}//${window.location.host}${link}`, '_blank');
  }

  public loadFiscalWB(){
    const queryParams: Params = {
      lob: this.selectedLob,
      dashboardUrl: this.dashboardUrl,
      webEditUrl: this.fiscalWBLink
    };
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }

  selectedUrl(){
    if(this.selectedLob === 'Homeowners' && this.HO_PA_Selected === 'HO'){
       return this.fiscalWBLink;
    }
    if(this.selectedLob === 'Personal Auto' &&  this.HO_PA_Selected === 'PA'){
       return this.fiscalWBPALink; 
     }
    if(this.selectedLob === 'Commercial Auto' && this.HO_PA_Selected === 'CA'){
      return this.fiscalWBCALink;     
    }
    if(this.selectedLob === 'Businessowners' && this.HO_PA_Selected === 'BO' ){
      return this.fiscalWBBOLink; 
    }
    if(this.selectedLob === 'General Liability' && this.HO_PA_Selected === 'GL' ){
      return this.fiscalWBGLLink; 
    }
    if(this.selectedLob === 'Commercial Property' && this.HO_PA_Selected === 'CF' ){
      return this.fiscalWBCFLink; 
    }
  }

  storeState(){
      this.states = [ {
      lob: this.selectedLob,
      dashboardUrl: this.dashboardUrl,
      webEditUrl: this.lobs.find(s => s.lobDescription === this.selectedLob).webEditUrl
    },{
      lob: this.selectedLob,
      dashboardUrl: this.dashboardUrl,
      webEditUrl: this.selectedUrl()
    }];
  }
  toggleFiscal() {
    this.isFiscalSelected = !this.isFiscalSelected;
    console.log('isFiscalSelected: ' + this.isFiscalSelected, this.states);
    if(this.isFiscalSelected){
      this.router.navigate([],{
        relativeTo: this.route,
        queryParams: this.states[1],
        queryParamsHandling: 'merge'
      });
    } else {
      this.router.navigate([],{
        relativeTo: this.route,
        queryParams: this.states[0],
        queryParamsHandling: 'merge'
      });

    } 
  }

}
