import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { IUserGuide, DataCubeResponse } from 'src/models';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { UserGuideService } from './user-guide.service';
import { DcubeResponseCacheService } from 'src/app/shared/service/dcube-response-cache.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.css']
})
export class UserGuideComponent implements OnInit {
  userGuides: IUserGuide[];
  selectControl = new FormControl();
  isEligible: boolean;
  isTrial = false;
  errorMessage = '';
  isUserAdmin = false;
  ngOnInit() {
    this.appService.getConfigStatus().subscribe((configStatus: boolean) => {
      if (configStatus) {
        console.log('UserGuideComponent : getConfigStatus: getUserGuides is being called.');
        this.getUserGuides();
      }
    });
  }
  constructor(private appService: AppService, private cacheService: DcubeResponseCacheService, private userGuideService: UserGuideService,
    private cookieService: CookieService) {
    this.appService.getTrialStatus().subscribe(isTrial => {
      console.log(`UserGuideComponent : constructor: isTrial is set to ${isTrial}`);
      this.isTrial = isTrial;
    });
  }

  GetDocument(id: string) {
    const uGuide = this.userGuides.find(ug => ug.id === id);
    const fileName = uGuide.url;
    this.userGuideService.getFileLink(fileName).subscribe(fileUrl => {
      if(fileUrl) {
        this.userGuideService.downloadFile(fileUrl).subscribe((file: Blob) => {
          if (window.navigator.msSaveOrOpenBlob) { // for IE and Edge
            window.navigator.msSaveBlob(file, fileName);
          } else {
            const url = window.URL.createObjectURL(file);
            const a = document.createElement('a');
            a.setAttribute('type', 'hidden');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
          }
        });
      }
    });
  }

  getUserGuides() {
      this.appService.isUserAdmin(this.cookieService.get('ticket')).then((data) => {
      this.isUserAdmin = data;
      if (this.isUserAdmin) {
        this.userGuideService.getAdminUserGuides(this.cookieService.get('ticket')).subscribe((result: DataCubeResponse) => {
          this.userGuides = result && result.userGuides;
          this.isEligible = true;
        });
      } else {
      this.userGuideService.getUserGuides(this.cookieService.get('ticket'), this.isTrial).subscribe((result: DataCubeResponse) => {
          this.userGuides = result && result.userGuides;
          this.isEligible = result && result.isEligible;
        });
      }
    });
  }
}
