import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppService } from 'src/app/app.service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { DataCubeResponse, ILob } from '../../../models/index';
import { DcubeResponseCacheService } from '../../shared/service/dcube-response-cache.service';

@Injectable({
  providedIn: 'root'
})
export class LobListService {
  constructor(private cacheService: DcubeResponseCacheService, private http: HttpClient) { }

  getLobList(ticket: string, isTrial: boolean): Observable<DataCubeResponse> {
    console.log(isTrial);
    if (isTrial) {
      return this.cacheService.getTrialDcubeResponse(ticket).pipe(
        tap(response => response),
        catchError(this.handleError)
      );
    } else {
      return this.cacheService.getDcubeResponse(ticket).pipe(
        tap(response => response),
        catchError(this.handleError)
      );
    }
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
