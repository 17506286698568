export const header_resources = {
    en: {
        menu: 'menu',
        person: 'person'
    }
};


export const loblist_resources = {
    en: {
        MarketLandscape: 'MarketLandscape'
    }
};


export const userguide_resources = {
    en: {
        FileName: 'FileName',
        Id: 'Id',
        Type: 'Type',
        DataCube: 'DataCube'
    }
};

export const vizcontainer_resources = {
    en: {
        Annual: 'Annual',
        Fiscal: 'Fiscal',
        MarketLandscape: 'MarketLandscape'
    }
};

export const downloadfiles_resources = {
    en: {
        folder: 'folder'
    }
};