import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ResponseContentType, RequestOptions } from '@angular/http';
import { AppService } from 'src/app/app.service';
import { catchError, tap, map, filter } from 'rxjs/operators';
import { IConfig } from '../../../models/index';
import { IUserGuide, FileToUpload, DataCubeResponse } from 'src/models';
import { DcubeResponseCacheService } from 'src/app/shared/service/dcube-response-cache.service';



@Injectable({
  providedIn: 'root'
})
export class UserGuideService {


  private appConfig: IConfig;
  constructor(private appService: AppService, private http: HttpClient, private cacheService: DcubeResponseCacheService) {
    this.appService.getConfigStatus().subscribe((configStatus: boolean) => {
      if (configStatus) {
        this.appConfig = this.appService.getGlobalConfig();
      }
    });

  }

  getUserGuides(ticket: string, isTrial: boolean): Observable<DataCubeResponse> {
      if (isTrial) {
      return this.cacheService.getTrialDcubeResponse(ticket).pipe(
        tap(response => response),
        catchError(this.handleError)
      );
    } else {
      return this.cacheService.getDcubeResponse(ticket).pipe(
        tap(response => response),
        catchError(this.handleError)
      );
    }
  }
  getAdminUserGuides(ticket: string): Observable<DataCubeResponse> {
    return this.cacheService.getAdminUserGuides(ticket).pipe(
      tap(response => response),
      catchError(this.handleError)
    );

  }
  

  handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);

  }

  downloadFile(url: string): Observable<Blob>{
    return this.http.get(`${url}`, { responseType: 'blob', headers: { accept: 'application/octet-stream' }});
    //return this.http.get(`${this.appConfig.datacubeApiUrl}/api/Dataload/DownloadFiles?url=${url}`, { responseType: 'blob', headers: { accept: 'application/octet-stream' }});

  }

  getFileLink(fileName: string): Observable<string>{
    return this.http.get(`${this.appConfig.datacubeApiUrl}/api/Dataload/GetDownloadLink?fileName=${fileName}`,{responseType:'text'})
    .pipe(map((res:any) => {return res;}));
  }
  
}

